body {
  background: var(--text);
  animation: backgroundFade 3s ease-in-out 0.5s; 
  animation-fill-mode: forwards;
} 

.container{
  display: flex;
  position: fixed;
  /* grid-template-columns: 1fr 1fr 1fr; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  overflow-y: scroll;
  
  padding-right: 0px;
}

.box{
  width: 10px;
  height: 10px;
  background: tomato;
  grid-column: 2;
}

.headerdiv{
  display: flex;
  flex-direction: column;
  grid-column: 2;
  width: 100%;
  position: relative;
  top: 0;
  padding: 1rem;
  align-items: center;
  color: var(--text);
  background: none;
  z-index: 1;
  animation-fill-mode: forwards;
  overflow-x: visible;
}

.bannertop {
  position: fixed;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  color: grey;
}

.about{
  position: relative;
  color: var(--text);
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  grid-template-columns: minmax(1.2rem, 1fr) minmax(auto, 57ch) minmax(1.2rem, 1fr);
}

.logowords {
  background: none;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 10rem;
  justify-content: center;
  font-family: 'Bebas Neue';
  font-weight: 800;
  font-size: 10rem;
  text-align: center;
  animation: spacing 4s ease-in 0.5s;
  animation-duration: 4s;
}
.subheading{
  text-align: center;
  font-size: larger;
  margin-top: 2vh;;
}


.logo {
  position: relative;
  width: 20vh;
  margin: 0vw;
  grid-column: 1;
  z-index: 2;
}



.about{ 
  position: relative;
  top: 30vh;
  animation: headerMove 2s ease-in-out 2s;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 600px) {
  .logowords {
    font-size: 30vw;
    height: 25vw
  }
  .logo {
    position: relative;
    grid-column: 2;
  }
  .headerdiv{
    position: relative;
    z-index: 2;
    background: none;
    overflow-x: hidden;

  }

  @keyframes spacing {
    from {
      letter-spacing: 5rem;
      font-size: 20rem;
      
    }
    to {
      letter-spacing: normal;
      font-size: 30vw;
  
    }
  }

  /* @keyframes logoScale {
    from {
      width: 8em; 
      top: 10vh;
      left: 0;
      transform: translate(0%, 0%);
      margin-top: 3vw;
    }
    to {
      width: 4em; 
      top: 0vh;
      left: 0;
      transform: translate(0%, 0%);
      margin-top: 3vw;
    }
  } */

}

@keyframes backgroundFade {
  from {
    background: var(--text);
  }
  to {
    background: var(--background);
  }
}





@media only screen and (min-width: 600px) {
  /* @keyframes logoScale {
    from {
      width: 20vh;
      
    }
    to {
      width: 8em; 
   
    }
  } */
  @keyframes spacing {
    from {
      letter-spacing: 5rem;
      font-size: 20rem;
      
    }
    to {
      letter-spacing: normal;
      font-size: 10rem;
  
    }
  }
}

