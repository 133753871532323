.mintheading {
    background: none;
    color: white;
    padding: 10px;
    padding-top: 20px;
  }
  .grid-container-tenants{
    display: grid;
    color: var(--text);
    top: 0;
    row-gap: 1.5rem;
    grid-template-columns: 1fr minmax(auto, 57ch) 1fr;
    overflow-x: hidden;
    margin-top: 100px;
} 

.grid-oneRow {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto;
    gap: 1em;
}
.logo-stamp {
    position: absolute;
    width: 20vw;
    max-width: 200px;
    padding: 1rem;
    transform: translate(30vw, 23vh);
}

.example1 {
    width: 100%;
    grid-row: 1;
}
.example2 {
    width: 100%;
    grid-row: 1;
}

  .grid-container{
    display: grid;
    
    top: 0;
    row-gap: 1.5rem;
    grid-template-columns: 1fr minmax(auto, 57ch) 1fr;
    overflow-x: hidden;
    min-height: 100vh;

} 
.whiteTxt {
    color: white;
}
.welcomeH1{
    font-size: 4rem;
    font-family: 'Bebas Neue';
    font-weight: bold;
    letter-spacing: 2px;
}

.welcomeTo{
    font-size: 11vw;
    font-family: 'Bebas Neue';
    font-weight: bold;
}
.threeCols {
    grid-column-start: 1;
    grid-column-end: 4;
    padding: 1em;
}

.fillbox {
    background: green;
}

.c {
    grid-column: 2;
}
.centerTxt {
    text-align: center;
}
.t {
    grid-column-start: 1;
    grid-column-end: 4;
    font-family: 'Bebas Neue';
    font-size: 10vw;
}

.welcome {
    background: #53A746;
    color: white;
    opacity: 0;
    animation: raiseOpacity 4s ease-in 1s;
    animation-fill-mode: forwards;
    margin-top: 100px;
}

.description {
    font-size: 1.5rem;
    
}

  p {
      grid-column: 2;
  }
  .content {
      grid-column: 2;
      height: 2000px;
      background: white;
      border-radius: 1rem;
  }


@media only screen and (max-width: 600px) {
    .example2 {
        width: 100%;
        grid-row: 2;
    }

    .welcomeH1 {
        font-size: 11vw;
    }
}

@keyframes raiseOpacity {
    from {
      opacity: 0.0;
    }
    to {
        opacity: 1.0;
    }
  }